const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getUserListAPI (page = 1, limit = 10, search = '') {
    const response = await fetch(`${API_URL}/admin/users?search=${search}&page=${page}&limit=${limit}`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.message))
    } else {
      return Promise.reject(new Error(resp.message))
    }
  },
  async getUserDetailAPI (userId) {
    const response = await fetch(`${API_URL}/admin/user/${userId}`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.message))
    } else {
      return Promise.reject(new Error(resp.message))
    }
  },
  async editUserAPI (userId, formData) {
    const response = await fetch(`${API_URL}/admin/user/${userId}/update`, {
      method: 'PUT',
      headers: {
        authorization: getToken()
      },
      body: formData
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  }
}
