export default {
  deleteNotification (state, notification) {
    state.ui.notifications = state.ui.notifications.filter(value => value.datetime !== notification)
  },
  addNotification (state, notification) {
    state.ui.notifications = [...state.ui.notifications, notification]
  },
  toggleSidebar (state) {
    state.ui.sidebar = !state.ui.sidebar
  },
  toggleActiveMenu (state, menu) {
    state.ui.activeMenus = state.ui.activeMenus.includes(menu)
      ? state.ui.activeMenus.filter(currentMenu => currentMenu !== menu)
      : [...state.ui.activeMenus, menu]
  }
}
