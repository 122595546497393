import api from '@/lib/api'

export default {
  async getProductList ({ dispatch }, { page, limit, type, search }) {
    try {
      const resp = await api.getProductListAPI(page, limit, type, search)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getProductDetail ({ dispatch }, productId) {
    try {
      const resp = await api.getProductDetailAPI(productId)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async createProduct ({ dispatch }, data) {
    try {
      const formData = new FormData()
      formData.append('image', data.image)
      formData.append('name', data.name)
      formData.append('productType', data.productType)
      formData.append('price', data.price)
      formData.append('total', data.total)
      formData.append('userId', data.userId)
      const resp = await api.createProductAPI(formData)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async changeStatusProduct ({ dispatch }, { productId, productInfo }) {
    try {
      const resp = await api.changeStatusProductAPI(productId, productInfo)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async uploadImageProductAc ({ dispatch }, { productId, image }) {
    try {
      const formData = new FormData()
      formData.append('image', image.file)
      const resp = await api.uploadImageProductAcAPI(productId, formData)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async updateDataProduct ({ dispatch }, { dataUpdate, productId }) {
    try {
      const formData = new FormData()
      Object.entries(dataUpdate).forEach(([key, value]) => {
        formData.append(key, value)
      })

      const resp = await api.updateProductAPI(formData, productId)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
