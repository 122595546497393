import api from '@/lib/api'

export default {
  async getUserList ({ dispatch }, { page, limit, search }) {
    try {
      const resp = await api.getUserListAPI(page, limit, search)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async getUserDetail ({ dispatch }, userId) {
    try {
      const resp = await api.getUserDetailAPI(userId)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async editUser ({ dispatch }, { userInfo, userId }) {
    try {
      const formData = new FormData()
      Object.entries(userInfo).forEach(([key, value]) => {
        formData.append(key, value)
      })
      const resp = await api.editUserAPI(userId, formData)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
