import api from '@/lib/api'

export default {
  async getTransactionsList ({ dispatch }, { page, limit, search }) {
    try {
      const resp = await api.getTransactionsListAPI(page, limit, search)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async getTransactionDetail ({ dispatch }, transactionId) {
    try {
      const resp = await api.getTransactionDetailAPI(transactionId)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async changeStatusTransaction ({ dispatch }, data) {
    try {
      const resp = await api.changeStatusTransactionAPI(data)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
