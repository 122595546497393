const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getTransactionsListAPI (page = 1, limit = 10, search = '') {
    const response = await fetch(`${API_URL}/admin/transactions?search=${search}&page=${page}&limit=${limit}`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async getTransactionDetailAPI (transactionId) {
    const response = await fetch(`${API_URL}/admin/transaction/${transactionId}`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  },

  async changeStatusTransactionAPI (data) {
    const { transactionId, ...dataUpdate } = data
    const response = await fetch(`${API_URL}/admin/transaction/${transactionId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: JSON.stringify(dataUpdate)
    })
    const resp = await response.json()
    if (!response.ok) {
      if (response.status === 400) {
        return Promise.reject(new Error(resp.error))
      }
    }
    return resp
  }
}
