<template>
  <div id="app">
    <the-notification />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
    TheNotification: () => import('@/components/UI/TheNotifications.vue')
  },
  mounted () {
    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1024 && this.$store.state.ui.sidebar) {
        this.$store.commit('toggleSidebar')
      }
    })
  }
}
</script>

<style lang="sass">
  @import "bulma/bulma.sass";
  @import "bulma-switch/src/sass/index.sass";
  @import "~bulma-checkradio/src/sass/index.sass";
</style>

<style lang="scss">

:root {
  --primary-gray: #5d5d5d;
  --secondary-gray: #8d8d95;
  --gray-91: #919191;
  --grey-smoke: #e7e9f0;
  --aqua: #00add8;
  --primary-violet: #b751b5;
  --primary-green: #96d064;
  --primary-white: #fdfdfd;
  --white-smoke: #f7f7fa;
  --orange: #de8c07;
  --red: #eb5e64;
  --green: #1b8e42;
  --warning: #ff9e00;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto !important;
  background-color: var(--white-smoke);
  font-family: 'Poppins', sans-serif;
}
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
#app > .hero {
  flex: 1;
}

h1, h2, h3, h4, h5, h6, a, p, span {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.is_grid {
  display: grid;
}

/* Chrome Autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
  box-shadow: 0 0 0 30px white inset;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #363636 !important;
}
input, button, .button {
  font-family: 'Poppins', sans-serif;
}
// Textos
.title_view {
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
  color: var(--primary-gray);
}
.font_30 {
  font-size: 30px;
  line-height: 35px;
}
.font_20 {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 1px;
}
.font_19 {
  font-size: 19px;
  line-height: 30px;
}
.font_18 {
  font-size: 18px;
  line-height: 22px;
}
.font_15 {
  font-size: 15px;
  line-height: 23px;
}
.font_14 {
  font-size: 14px;
  line-height: 25px;
}
.font_12 {
  font-size: 12px;
  line-height: 1.5;
}
.font_10 {
  font-size: 10px;
  line-height: 16px;
}
.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}
// Colores
.has_gray {
  color: var(--primary-gray);
}
.has_lightgrey {
  color: var(--secondary-gray);
}
.is_gray_99 {
  color: var(--gray-91);
}
.has_violet, .has_violet:hover {
  color: var(--primary-violet);
}
.has_violet:hover {
  font-weight: bold;
}
.has_danger {
  color: var(--red)
}
.has_warning {
  color: var(--warning)
}
.has_success {
  color: var(--green)
}
// Forms
.input {
  padding: 1.3rem;
  border-radius: 8px;
  border: solid 1px #707070ab;
  background-color: #fff;
  font-size: 14px;
  line-height: 1.5;
  color: #5d5d5d;
  transition: all 0.30s ease-in-out;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
}
.textarea {
  border-radius: 8px;
  border: solid 1px #707070ab;
  resize: none;
}
.select select {
  border: solid 1px #707070ab;
  border-radius: 8px;
  font-size: 14px;
}
.input:focus,
.textarea:focus,
.select select:focus,
.is-focused.input,
.is-focused.textarea,
.select select.is-focused,
.input:active,
.textarea:active,
.select select:active,
.is-active.input,
.is-active.textarea,
.select select.is-active {
  outline: none;
  box-shadow: 0 0 4px var(--primary-violet);
  border-color: var(--primary-violet);
}
// Botones
.button[disabled],
.button[disabled]:hover{
  background-color: white;
  border-color: #dbdbdb;
  color: inherit;
  box-shadow: none;
  opacity: 0.5;
}
.btn_violet {
  background-color: var(--primary-violet);
  border-color: var(--primary-violet);
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all 150ms ease-in-out;
}
.btn_violet:hover,
.btn_violet:focus,
.btn_violet:active,
.btn_violet.is-active,
.btn_violet:hover,
.btn_violet:focus,
.btn_violet:active,
.btn_violet.is-active {
  background-color: var(--primary-violet);
  border-color: var(--primary-violet);
  box-shadow: 0 0 2px 0 #b751b5 inset, 0 0 2px 1px #b751b5;
  color: white;
}

.btn_aqua {
  background-color: var(--aqua);
  border-color: var(--aqua);
  color: #fff;
  border-radius: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all 150ms ease-in-out;
}
.btn_aqua:hover,
.btn_aqua:focus,
.btn_aqua:active,
.btn_aqua.is-active,
.btn_aqua:hover,
.btn_aqua:focus,
.btn_aqua:active,
.btn_aqua.is-active {
  background-color: var(--aqua);
  border-color: var(--aqua);
  box-shadow: 0 0 2px 0 var(--aqua) inset, 0 0 2px 1px var(--aqua);
  color: white;
}

.is_outlined {
  background-color: transparent;
  border-color: #919191;
  color: #919191;
  border-radius: 3px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.2px;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: all 150ms ease-in-out;
}
.is_outlined:hover,
.is_outlined:focus,
.is_outlined:active,
.is_outlined.is-active,
.is_outlined:hover,
.is_outlined:focus,
.is_outlined:active,
.is_outlined.is-active {
  background-color: transparent;
  border-color: var(--primary-gray);
  color: var(--primary-gray);
}

.pagination_container {
  box-shadow: -1px 10px 20px 0 rgba(188, 188, 222, 0.27);
  background-color: #ffffff;
  padding: 1rem 0;
  border-top: solid 1px #e6e6e6;
}

// Modal
.modal_custom {
  .modal-card {
    border-radius: 10px;
    box-shadow: 0 2px 45px 0 rgba(91, 91, 106, 0.31);
    background-color: #fff;
    padding: 57px 58px;
  }
  .modal-card-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.5;
    color: #5d5d5d;
  }
  .close_modal_icon {
    color: var(--aqua);
    font-size: 28px;
    cursor: pointer;
  }
  .modal-card-head,
  .modal-card-foot {
    background-color: #fff;
  }
  .modal-card-head {
    border-bottom: none;
  }
  .modal-card-foot {
    border-top: none;
  }
  .modal-card-foot {
    justify-content: center;
  }
  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    color: #5d5d5d;
  }
  .button {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .font_30 {
    font-size: 29px;
  }
  .title_view {
    font-size: 28px;
  }
  .font_20 {
    font-size: 19px;
  }
  .font_19 {
    font-size: 18px;
  }
  .font_18 {
    font-size: 17px;
  }
  .font_15 {
    font-size: 14px;
  }
  .font_12 {
    font-size: 12px;
  }
  .label {
    font-size: 15px;
  }
}
</style>
