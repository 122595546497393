import auth from './auth'
import adminUsers from './adminUsers'
import users from './users'
import products from './products'
import dashboard from './dashboard'
import transactions from './transactions'

export default {
  ...auth,
  ...adminUsers,
  ...users,
  ...products,
  ...dashboard,
  ...transactions
}
