const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getProductListAPI (page = 1, limit = 10, type = 'T', search = '') {
    const response = await fetch(`${API_URL}/admin/products?search=${search}&page=${page}&limit=${limit}&type=${type}`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.message))
    } else {
      return Promise.reject(new Error(resp.message))
    }
  },
  async getProductDetailAPI (productId) {
    const response = await fetch(`${API_URL}/admin/product/${productId}`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.message))
    } else {
      return Promise.reject(new Error(resp.message))
    }
  },
  async createProductAPI (productData) {
    const response = await fetch(`${API_URL}/admin/products`, {
      method: 'POST',
      headers: {
        // 'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: productData
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },
  async changeStatusProductAPI (productId, productInfo) {
    const response = await fetch(`${API_URL}/admin/product/${productId}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: JSON.stringify(productInfo)
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },
  async uploadImageProductAcAPI (productId, formData) {
    const response = await fetch(`${API_URL}/admin/product/${productId}/image`, {
      method: 'PUT',
      headers: {
        // 'Content-Type': 'application/json',
        authorization: getToken()
      },
      body: formData
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  },
  async updateProductAPI (formData, productId) {
    const response = await fetch(`${API_URL}/admin/product/${productId}/update`, {
      method: 'PUT',
      headers: {
        authorization: getToken()
      },
      body: formData
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.error))
    } else {
      return Promise.reject(new Error('Request Error'))
    }
  }
}
