const API_URL = process.env.VUE_APP_API_URL
const getToken = () => localStorage.getItem('access-token')

export default {
  async getDashboardAPI () {
    const response = await fetch(`${API_URL}/admin/dashboard`, {
      headers: {
        authorization: getToken()
      }
    })
    const resp = await response.json()
    if (response.ok) {
      return resp
    } else if (response.status === 400) {
      return Promise.reject(new Error(resp.message))
    } else {
      return Promise.reject(new Error(resp.message))
    }
  }
}
