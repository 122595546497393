export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    // meta: { auth: true },
    children: [
      {
        path: '/',
        name: 'first-view',
        component: () => import('@/views/Dashboard/Dashboard.vue')
      },
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/Dashboard/Dashboard.vue')
      },
      {
        path: '/usuarios',
        component: () => import('@/views/RouteTransport.vue'),
        children: [
          {
            path: '/',
            name: 'usuarios',
            component: () => import('@/views/Customers/Customers.vue')
          },
          {
            path: 'detalle-usuario/:userId',
            name: 'detalle-usuario',
            component: () => import('@/views/Customers/CustomerDetail.vue')
          }
        ]
      },
      {
        path: '/productos',
        component: () => import('@/views/RouteTransport.vue'),
        children: [
          {
            path: '/',
            name: 'productos',
            component: () => import('@/views/Products/Products.vue')
          },
          {
            path: 'detalle-producto/:productId',
            name: 'detalle-producto',
            component: () => import('@/views/Products/ProductDetail.vue')
          }
        ]
      },
      {
        path: '/transacciones',
        component: () => import('@/views/RouteTransport.vue'),
        children: [
          {
            path: '/',
            name: 'transacciones',
            component: () => import('@/views/Transactions/Transactions.vue')
          },
          {
            path: 'detalle-transaccion/:transactionId',
            name: 'detalle-transaccion',
            component: () => import('@/views/Transactions/TransactionDetail.vue')
          }
        ]
      },
      {
        path: '/administradores',
        component: () => import('@/views/RouteTransport.vue'),
        children: [
          {
            path: '/',
            name: 'administradores',
            component: () => import('@/views/Administrators/Administrators.vue')
          }
        ]
      },
      {
        path: '/mi-perfil',
        component: () => import('@/views/RouteTransport.vue'),
        children: [
          {
            path: '/',
            name: 'mi-perfil',
            component: () => import('@/views/Profile/Profile.vue')
          }
        ]
      }
    ]
  }
]
