import api from '@/lib/api'
import router from '@/router'

export default {
  async login ({ dispatch }, data) {
    try {
      const { token, success, message = null } = await api.loginAPI(data)
      if (!success) throw new Error(message)
      localStorage.setItem('access-token', token)
      router.replace({ name: 'first-view' })
      return success
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async validateSession ({ commit, dispatch }) {
    try {
      const response = await api.validateSession()
      commit('setAuthValues', { ...response })
      return { complete: true, payload: response }
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      localStorage.removeItem('access-token')
      return { complete: false, message: error.message }
    }
  },

  async userForgot ({ dispatch }, email) {
    try {
      const { message } = await api.userForgotAPI(email)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async passwordRecovery ({ dispatch }, data) {
    try {
      const { message } = await api.passwordRecoveryAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },

  async changePassword ({ dispatch }, data) {
    try {
      const response = await api.changePasswordAPI(data)
      if (!response) throw new Error(response.message)
      dispatch('addTemporalNotification', { type: 'success', message: response.message })
      return response
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
