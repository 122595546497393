import Vue from 'vue'
import Router from 'vue-router'
import routes from './routes'
import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (window.innerWidth <= 1024 && store.state.ui.sidebar) {
    store.commit('toggleSidebar')
  }
  const requiresAuth = to.matched.some(route => route.meta.auth)
  const authed = localStorage.getItem('access-token')
  const signInObject = { name: 'login', query: { redirect: to.fullPath } }

  if (requiresAuth && !authed) return next(signInObject)

  if (to.name === 'login' || to.name === 'recovery' || to.name === 'forgot-password') return next()

  store.dispatch('validateSession')
    .then(response => {
      if (!response.complete) {
        return next(signInObject)
      }
      return next()
    })
    .catch(() => {
      localStorage.removeItem('access-token')
      next({ name: 'login' })
    })

  // TODO: Quitar
  // return next()
})

export default router
