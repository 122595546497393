import api from '@/lib/api'

export default {
  async getDashboard ({ dispatch }) {
    try {
      const resp = await api.getDashboardAPI()
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
