import api from '@/lib/api'

export default {
  async getAdminUserList ({ dispatch }, { page, limit }) {
    try {
      const resp = await api.getAdminUserListAPI(page, limit)
      if (!resp.success) throw new Error(resp.message)
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async createAdminUser ({ dispatch }, data) {
    try {
      const resp = await api.createAdminUserAPI(data)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async editAdminUser ({ dispatch }, { userId, data }) {
    try {
      const resp = await api.editAdminUserAPI(userId, data)
      if (!resp.success) throw new Error(resp.message)
      dispatch('addTemporalNotification', { type: 'success', message: resp.message })
      return resp
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  },
  async myProfileUpdate ({ dispatch }, data) {
    try {
      const { message } = await api.myProfileUpdateAPI(data)
      dispatch('addTemporalNotification', { type: 'success', message: message })
      return true
    } catch (error) {
      dispatch('addTemporalNotification', { type: 'danger', message: error.message })
      return false
    }
  }
}
